import { Content } from '@prismicio/client';
import { PrismicRichText, SliceComponentProps } from '@prismicio/react';
import utils, { Alignment } from 'components/PRISMIC/utils';

import type { JSX } from 'react';
import classNames from 'classnames';

/**
 * Props for `HeroCopy`.
 */
export type HeroCopyProps = SliceComponentProps<Content.HeroCopySlice>;

/**
 * Component for "HeroCopy" Slices.
 */
const HeroCopy = ({ slice }: HeroCopyProps): JSX.Element => {
  const textStyles = '[&_pre]:text-wrap';
  return (
    <div
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={classNames(
        utils.getAlignClass(Alignment[slice.primary.alignment]),
        textStyles
      )}
    >
      <PrismicRichText
        components={{
          paragraph: ({ children }) => (
            <p>
              {children}
              <br />
            </p>
          )
        }}
        field={slice.primary.text}
      />
    </div>
  );
};

export default HeroCopy;
